import * as React from "react";
import PropTypes from 'prop-types';
import {Box, makeStyles, Typography} from '@material-ui/core';
import { StaticImage } from "gatsby-plugin-image";

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 800,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    box: {
        position: "relative",
        textalign: "center",
    },
    link:{
        color: theme.palette.background.default,
        textDecoration: "none",
        "&:hover": {
            color: theme.palette.primary.main,
        },
    }
  }));


const TicketLink = ({title, link}) => {
    const classes = useStyles();
    return (
        <Box className={classes.box}>
            <StaticImage src="../../images/lineuptitle.png" alt={title} className={classes.titleImage} />
            <Typography variant="body1" component="h2" className={classes.title}>
            <a href={link} target="_blank" rel="noreferrer" className={classes.link}>{title}</a>
            </Typography>
        </Box>
    )
}

TicketLink.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
}

export default TicketLink