import * as React from "react"
import { Container,Box, Grid, Typography, makeStyles } from "@material-ui/core"
import Seo from "../../components/seo"
import Menu from "../../components/festival/menu"
import PropTypes from 'prop-types';
import TicketLink from "../../components/festival/ticketLink"

const useStyles = makeStyles(theme => ({
  text: {
    fontWeight: 800
  }
}));

const TicketDay = ({day, price, link, addendum, extra}) => {
    const classes = useStyles();
  return (
    <Grid container>
      <Grid item sm={4} xs={8}>
        <Typography variant="h5" component="h2" color="primary" className={classes.text}>{day} 
          </Typography>
          {addendum && <Typography variant="body1" component="h3" color="primary">{addendum}</Typography>}
          {extra && <Typography variant="body2" component="h4" color="primary">{extra}</Typography>}

      </Grid>
      <Grid item sm={2} xs={4}>
        <Typography variant="h5" component="h2" color="secondary" className={classes.text}>{price}</Typography>
      </Grid>
      <Grid item sm={6} xs={12}>
        <TicketLink title="KOOP TICKETS" link={link}/>    
      </Grid>
    </Grid>)
}

const TicketsPage = () => {
  return (

    <Container maxWidth="lg">
    <Seo title="Tomatostories - Tickets" />
      <Box>
      <Grid container spacing={2}>
          <Grid item xs={12}>
          <Menu/>
          </Grid>
      <Grid item xs={8}>
        <TicketDay day="WOENSDAG 18/08" price="€ 12" link="https://www.universe.com/events/tomato-festival-tickets-P6FXL4"/>
        <TicketDay day="DONDERDAG 19/08" price="€ 18" link="https://www.universe.com/events/tomato-festival-tickets-P6FXL4"/>
        <TicketDay day="VRIJDAG 20/08" addendum="NAMIDDAG" extra="(gratis voor kinderen jonger dan 3 jaar)" price="€ 18" link="https://www.universe.com/events/tomato-festival-tickets-P6FXL4"/>
        <TicketDay day="VRIJDAG 20/08" addendum="AVOND" price="€ 18" link="https://www.universe.com/events/tomato-festival-tickets-P6FXL4"/>
      </Grid>
      </Grid>
      </Box>
    </Container>

)}

TicketDay.propTypes = {
  day: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  addendum: PropTypes.string,
  extra: PropTypes.string
}
export default TicketsPage
